<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  error: {
    statusCode: number
    message: string
    stack: string
  }
}>()

const statusCode = computed(() => {
  if (props.error?.statusCode)
    return props.error?.statusCode

  return 500
})
</script>

<template>
  <div>
    <Errors404 v-if="statusCode === 404" />
    <ErrorsErrorCatchAll v-else :error="error" />
  </div>
</template>
