<script setup lang="ts">

</script>

<template>
  <div breakpoint="ultraWideDesktop" component="[object Object]" slice-index="0" class="DeviseGlobalErrorsFourOhFour">
    <div class="flex flex-col items-center bg-left-bottom bg-cover text-center text-abs-white" style="padding-top: 35px; padding-bottom: 1000px; background-image: url(https://playa-cms-assets.s3.amazonaws.com/styled/404-page2.jpg/01d4dba860335822b7335da9063f61da);">
      <div class="text-5xl uppercase title text-white pt-16 mb-8 font-thin">
        404 ERROR
        <div class="pt-1 text-sm uppercase font-bold text-slate-300">
          Sorry, we can't find that page.
        </div>
      </div>
      <div class="flex flex-col space-y-8 text-white w-3/5 text-lg font-light">
        <p>
          In the meantime picture yourself sitting on this beach or click a useful link below:
        </p>

        <p class="font-medium">
          <a href="/" rel="noopener noreferrer nofollow" style="color: #FFFFFF">Home Page</a><br>
          <a href="/specials" rel="noopener noreferrer nofollow" style="color: #FFFFFF">Special Offers</a>
        </p>
      </div>
    </div>
  </div>
</template>
