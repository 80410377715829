import { defineNuxtPlugin } from '#imports'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()

  return {
    provide: {
      playa: {
        rest_api_base_url: `${config.public.voixApiBaseUrl}/api`,
      },
    },
  }
})
